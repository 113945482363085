import React, { useState } from "react";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import Button from "~/components/button";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={14}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 14</H2>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={true}
            setFullScreen={setStudentFullScreen}
          >
            <p>This lesson focused on thinking about risks and benefits when making decisions about health issues.</p>
            <p>Pull up the copies of the rubric you have been working on. At this point, you will add new criteria to your rubric focusing on these ideas. What would you add to a rubric about risks and benefits to help you make decisions about health?</p>
            <p>Talk with your teammates about what statements to add to your rubric. Think back to what you did in this lesson to help you. For example, you started the lesson by looking at a commercial for a prescription medicine. You saw that the commercial included both risks and benefits. How would you write that as a statement for your rubric?</p>
            <p>After you have finished adding criteria to your rubric, look again at the CBD websites and use your new rubric section about risk and benefits to score those sites.</p>

            <div className="flex flex-wrap md:flex-nowrap flex-col">
              <Button
                action="secondary"
                color="indigo"
                href="/article/cbd/cbd-now"
                icon="external"
                title="CBD website 1"
              >
                CBD website 1
              </Button>
              <Button
                action="secondary"
                className="mt-5"
                color="indigo"
                href="/article/cbd/cbd-truth"
                icon="external"
                title="CBD website 2"
              >
                CBD website 2
              </Button>
              <Button
                action="secondary"
                className="mt-5"
                color="indigo"
                href="/article/cbd/cansativa-organics"
                icon="external"
                title="CBD website 3"
              >
                CBD website 3
              </Button>
            </div>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={true}
            setFullScreen={setTeacherFullScreen}
          >
            <p>Have students get their rubrics that they have been working with. Their goal is to add criteria to the rubric that relate to benefits and risks. Encourage them to think about what they learned in the lesson and to discuss their ideas with their teammates.</p>
            <p>If students need help getting started, you can remind them that they learned about benefits. Prompt them to write a statement along the lines of “The information clearly describes the benefits of the product or treatment.”</p>
            <p>Students will also revisit the CBD websites and use their rubrics to score those sites for benefits and risks.</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

